<template>
  <b-overlay
    :show="!shortenText && !isHistory"
    rounded="sm"
    no-fade
    spinner-variant="primary"
    variant="transparent"
    :opacity="0.8"
  >
    <!-- SECTION - THÔNG TIN CHUYẾN BAY -->
    <b-card
      header-bg-variant="blue-gradient py-50 mb-0"
      class="mb-0 border-info overflow-auto"
      no-body
    >
      <!-- <app-collapse>
        <app-collapse-item
          :is-visible="reservationsData.itineraries.length <= 2 ? true : tripIndex === 0 ? true : false"
          :header-bg-variant="'warning'"
          title
          body-class="px-0 mb-0"
          :class-body="'px-50'"
        > -->
      <!-- ANCHOR - Collapse HEADER -->
      <template #header>
        <div class="d-flex-center justify-content-start w-100">
          <h6 class="text-airline font-medium-3 m-0 fw-700">
            {{ $t(`reservation.shortenBooking.title${textType}`) }}
          </h6>

          <!-- ANCHOR RELOAD BUTTON -->
          <div
            v-if="['1S', '1S_CTRL', '1G'].includes(reservationsData.source)"
            class="ml-1"
          >
            <BButton
              v-b-tooltip.hover.v-info.window
              :title="$t(`reservation.shortenBooking.reloadButtonTooltip`)"
              variant="flat-primary"
              class="p-50 rounded-circle"
              @click.stop="handleReload"
            >
              <feather-icon
                icon="RotateCwIcon"
                size="20"
                :class="loading ? 'spinner-1s' : ''"
              />
            </BButton>
          </div>
        </div>
      </template>

      <BAlert
        v-if="isHistory && !shortenText"
        show
        variant="warning"
        class="m-1 px-2 py-50"
      >
        <div
          v-if="loading"
          class="py-25"
        >
          Đang tải dữ liệu...
        </div>
        <div
          v-else
          class="py-50"
        >
          Vui lòng nhấn nút tải lại hoặc tải vé rút gọn để xem HISTORY!
        </div>
      </BAlert>

      <div
        v-else
        class="px-50 pt-50"
      >
        <b-form-textarea
          v-model="shortenText"
          class="font-weight-bolder text-uppercase overflow-auto"
          rows="10"
          max-rows="40"
          style="font-size: 16px; line-height: 24px; background-color:#ffffc0; color:#000; font-family:'Calibri', sans-serif;"
        />

        <div class="d-flex-center">
          <!-- style="max-width: 256px;" -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="my-75 mr-1 text-nowrap float-md-right"
            variant="primary"
            :disabled="!shortenText || disabledCopy"
            pill
            @click="copyShortenText"
          >
            {{ $t(`reservation.shortenBooking.copy${textType}`) }}
          </b-button>
        </div>
      </div>
      <!-- </app-collapse-item>
      </app-collapse> -->
    </b-card>
    <!-- !SECTION -->
  </b-overlay>
</template>

<script>
import {
  BCard, BOverlay, BFormTextarea, BButton, VBTooltip, BAlert,
} from 'bootstrap-vue'
import {
  // onMounted,
  toRefs, ref,
} from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { useClipboard } from '@vueuse/core'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BCard,
    BOverlay,
    BFormTextarea,
    BButton,
    BAlert,

    // eslint-disable-next-line vue/no-unused-components
    AppCollapse,
    // eslint-disable-next-line vue/no-unused-components
    AppCollapseItem,

  },
  directives: {
    Ripple, 'b-tooltip': VBTooltip,
  },
  props: {
    shortenText: {
      type: String,
      default: () => '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    reservationsData: {
      type: Object,
      default: () => {},
    },
    isHistory: {
      type: Boolean,
      default: false,
    },
    textType: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const shortenText = toRefs(props).shortenText

    const disabledCopy = ref(false)
    const { copy } = useClipboard()
    function copyShortenText() {
      disabledCopy.value = true
      setTimeout(() => {
        disabledCopy.value = false
      }, 1000)

      copy(shortenText.value)
        .then(() => {
          this.$root.toastSuccess('reservation.sms.copySuccess')
        })
        .catch(() => {
          this.$root.toastError('reservation.sms.copyFail')
        })
    }

    function handleReload() {
      emit('get-shorten-booking-handle')
    }

    return {
      copyShortenText,
      disabledCopy,
      handleReload,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.payment-div {
  position: sticky;
  bottom: 0;
  z-index: 10;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  border-radius: 8px;
}

.min-h-unset {
  min-height: unset;
}
.rotate-180 {
  transition: all 0.25s ease-out;
  transform: rotate(-180deg);
}
</style>
