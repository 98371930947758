var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": !_vm.shortenText && !_vm.isHistory,
      "rounded": "sm",
      "no-fade": "",
      "spinner-variant": "primary",
      "variant": "transparent",
      "opacity": 0.8
    }
  }, [_c('b-card', {
    staticClass: "mb-0 border-info overflow-auto",
    attrs: {
      "header-bg-variant": "blue-gradient py-50 mb-0",
      "no-body": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-center justify-content-start w-100"
        }, [_c('h6', {
          staticClass: "text-airline font-medium-3 m-0 fw-700"
        }, [_vm._v(" " + _vm._s(_vm.$t("reservation.shortenBooking.title".concat(_vm.textType))) + " ")]), ['1S', '1S_CTRL', '1G'].includes(_vm.reservationsData.source) ? _c('div', {
          staticClass: "ml-1"
        }, [_c('BButton', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-info.window",
            modifiers: {
              "hover": true,
              "v-info": true,
              "window": true
            }
          }],
          staticClass: "p-50 rounded-circle",
          attrs: {
            "title": _vm.$t("reservation.shortenBooking.reloadButtonTooltip"),
            "variant": "flat-primary"
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.handleReload($event);
            }
          }
        }, [_c('feather-icon', {
          class: _vm.loading ? 'spinner-1s' : '',
          attrs: {
            "icon": "RotateCwIcon",
            "size": "20"
          }
        })], 1)], 1) : _vm._e()])];
      },
      proxy: true
    }])
  }, [_vm.isHistory && !_vm.shortenText ? _c('BAlert', {
    staticClass: "m-1 px-2 py-50",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm.loading ? _c('div', {
    staticClass: "py-25"
  }, [_vm._v(" Đang tải dữ liệu... ")]) : _c('div', {
    staticClass: "py-50"
  }, [_vm._v(" Vui lòng nhấn nút tải lại hoặc tải vé rút gọn để xem HISTORY! ")])]) : _c('div', {
    staticClass: "px-50 pt-50"
  }, [_c('b-form-textarea', {
    staticClass: "font-weight-bolder text-uppercase overflow-auto",
    staticStyle: {
      "font-size": "16px",
      "line-height": "24px",
      "background-color": "#ffffc0",
      "color": "#000",
      "font-family": "'Calibri', sans-serif"
    },
    attrs: {
      "rows": "10",
      "max-rows": "40"
    },
    model: {
      value: _vm.shortenText,
      callback: function callback($$v) {
        _vm.shortenText = $$v;
      },
      expression: "shortenText"
    }
  }), _c('div', {
    staticClass: "d-flex-center"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "my-75 mr-1 text-nowrap float-md-right",
    attrs: {
      "variant": "primary",
      "disabled": !_vm.shortenText || _vm.disabledCopy,
      "pill": ""
    },
    on: {
      "click": _vm.copyShortenText
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("reservation.shortenBooking.copy".concat(_vm.textType))) + " ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }